.hero {
    transition: 0.3s;
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: -80px;
    min-height: 100vh;
  }
  .hero-video{
    position: relative;
    object-fit: cover;
    max-height: 100vh;
  }
  .hero-video::-webkit-media-controls-picture-in-picture-button {
    display: none;
  }
  .title {
    font-size: 3rem;
    display: flex;
    position:absolute;
    text-align: center;
    color: #fff;
    top: 100px;
    z-index: 10;
  }
  .container {
    padding: 2px 16px;
  }
  .content-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 128, 0, 0.50);
    background-color: #81C784;
    opacity: 0.9;
  }
  