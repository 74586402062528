.news-container {
    position: absolute;
    top: 80px;
    bottom: 200px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.news-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.news-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.news-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
    text-align: center;
}
.news-text-body {
    color: white;
    font-size: 2.5rem;
    font-size: 4vh;
    font-size: 3.5vh;
    margin-top: 6vh;
    position: absolute;
    top: 7vh;
    bottom: 10vh;
    overflow: auto;
    background-color: rgba(0,0,0, 0.3);
    height: 78%;
    width: 90%;
    transition: transform 0.2s;
    text-align: justify;
    overflow-y: auto;
}
.news-text-body:hover {
    transform: scale(1.05);
}
.news-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
.date {
    float: left;
    text-decoration: underline;
}
.news-divider {
    width: 50%;
    position: absolute;
    left: 25%;
}
@media screen and (max-width: 1100px) {
    .news-text-title {
        margin-top: 4vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .news-text-body {
        margin-top: 3vh;
        font-size: 1.5rem;
        font-size: 2.5vh;
        font-size: 1rem;
    }
}