.footer-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    background-color: rgba(129,199,132, 0.8);
    background-color: rgba(104, 187, 89, 0.7);
    background-color: rgba(134, 200, 188, 0.9);
    background-color: rgba(104, 187, 89, 0.7);
    color: white;
    height: 120px;
    margin-top: -120px;
  }
  .footer-contents {
    width:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-container h3 {
    margin-bottom: 10px;
  }
  
  .footer-container ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-container li {
    padding-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .footer-container a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .footer-container a:hover {
    color: black;
  }
  .social-media {
    display: flex;
    width: auto;
    margin-top: 5px;
  }
  .social-media a {
    color: white;
    font-size: 24px;
    text-decoration: none;
  }
  .social-media a:hover {
    height: auto;
    border: none;
  }
  .footer-divider {
    margin-top: 1%;
    width: 100%;
    border-top:1px solid white;
  }
  .credits a {
    font-size: 0.8rem;
  }
  .mail {
    margin-top: 10px;
    margin-bottom: 10px;
  }