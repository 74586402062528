.language-switcher {
  background-color: rgba(104, 187, 89, 0.7);
  max-height: 80px;
  overflow: hidden;
  height: 100%;
  cursor: pointer;
}
.language-switcher:hover {
}

.language-switcher-button {
    max-height: 80px;
    height: 100%;
  }
  .language-switcher-button:hover {
  }
  
  .language-switcher-button img {
    height: calc(80px - (20px * 2));
  }
  .languages {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .language-container {
    height: 100%;
    width: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .language-container:hover {
    color: black;
    border-bottom: 4px solid #000;
    transition: all 0.2s ease-out;
  }
  .language-container:active {
    background-color: white;
  }
  .language-container img {
    width: 50px;
  }
  .language-container span {
    font-size: clamp(16px, calc(0.7vw + 0.7vh), 20px);
    max-width: 100%;
  }
  
@media screen and (max-width: 1200px) {
  .language-container span {
    font-size: clamp(13px, calc(0.7vw + 0.7vh), 16px);
    max-width: 100%;
  }
  /*
    .language-switcher-button:hover {
        background-color: rgba(104, 187, 89, 0.7);
    }
    .language-switcher-button:active {
        background-color: #3e8e41;
    }
    */
}