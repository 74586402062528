.gallery-container {
    position: absolute;
    top: 80px;
    bottom: 200px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gallery-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.gallery-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.gallery-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
    text-align: center;
}
.gallery-text-body {
    color: white;
    font-size: 2.5rem;
    font-size: 4vh;
    margin-top: 6vh;
    position: absolute;
    top: 7vh;
    bottom: 10vh;
    overflow: auto;
    background-color: rgba(0,0,0, 0.3);
    height: 78%;
    width: 90%;
    transition: transform 0.2s;
    overflow-x: hidden;
}
.gallery-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
.image-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(150px, 1fr));
    grid-row-gap: 0;
    max-height: 50%;
    height: 50%;
}

.image-grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.image-grid img:hover {
    transform: scale(1.1);
    transition: transform ease-in-out 0.2s;
}
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
}

.fullscreen img {
    max-width: 80%;
    max-height: 80%;
}

.fullscreen p {
    margin-top: 1%;
    color: white;   
}
.left-arrow {
    position:absolute;
    fill: white;
    z-index:10;
    cursor:pointer;
    left:10%;
}
.right-arrow {
    position:absolute;
    fill: white;
    z-index:10;
    cursor:pointer;
    right:10%;
}
@media screen and (max-width: 1100px) {
    .gallery-text-title {
        margin-top: 4vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .gallery-text-body {
        margin-top: 3vh;
        font-size: 1.5rem;
        font-size: 2.5vh;
    }
    .image-grid {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    .left-arrow {
        scale: 0.5;
    }
    .right-arrow {
        scale: 0.5;
    }
}