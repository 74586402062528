.roster-container {
    position: absolute;
    top: 80px;
    bottom: 200px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.roster-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.roster-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.roster-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
    text-align: center;
}
.roster-text-body {
    color: white;
    font-size: 2.5rem;
    font-size: 3.5vh;
    margin-top: 6vh;
    position: absolute;
    top: 7vh;
    bottom: 10vh;
    overflow: auto;
    background-color: rgba(0,0,0, 0.3);
    height: 78%;
    width: 90%;
    transition: transform 0.2s;
    overflow: auto;
}
.roster-text-body:hover {
    transform: scale(1.05);
}
.roster-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
.roster {
    display: flex;
    flex-direction: column;
  }
  .manager-team-title{
    text-align: center;
    font-size: calc(1.5vw + 1.5vh);
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid gray;
  }
  .team-title {
    text-align: center;
    font-size: calc(1.5vw + 1.5vh);
    border: 1px solid gray;
    background-color: rgba(0,0,0, 0.3);
    padding-left: 10%;
  }
  .managers {
    display: flex;
    justify-content: center;
  }
  
  .manager {
    margin: 0 2%;
    font-size: calc(1.4vw + 1.4vh);
  }
  
  .teams {
    display: flex;
  }
  
  .team {
    flex: 1;
    margin: 0 0;
    background-color: rgba(0,0,0, 0.3);
    text-align: center;
    border: 1px solid gray;
  }
  .player {
    display: flex;
    align-items: center;
    font-size: calc(1.2vw + 1.2vh);
    text-align: center;
    height: 100px;
    width: 100%;
  }
  .player-number {
    border: 1px solid gray;
    height: 100%;
    width: 8%;
    display: flex;
    padding: 0 2%;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: rgba(0,0,0, 0.3);
  }
  .player-name {
    border: 1px solid gray;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0 2%;
    align-items: center;
    justify-content: center;
  }
  .player-name:hover {
    background-color: rgba(104, 187, 89, 0.7);
    color: black;
  }
@media screen and (max-width: 1100px) {
    .roster-text-title {
        margin-top: 4vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .roster-text-body {
        margin-top: 3vh;
        font-size: 1.5rem;
        font-size: 2.5vh;
    }
    .team-title {
      padding-left: 0;
    }
}