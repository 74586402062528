.schedule-container {
    position: absolute;
    top: 80px;
    bottom: 200px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.schedule-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.schedule-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.schedule-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
    text-align: center;
}
.schedule-text-body {
    color: white;
    font-size: 2.5rem;
    font-size: 4vh;
    margin-top: 6vh;
    position: absolute;
    top: 7vh;
    bottom: 10vh;
    overflow: auto;
    background-color: rgba(0,0,0, 0.3);
    height: 78%;
    width: 90%;
    transition: transform 0.2s;

}
.schedule-text-body:hover {
    transform: scale(1.05);
}
.training-schedule {
    margin: 0 0;
    background-color: rgba(0,0,0, 0.3);
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.training-title{
    text-align: center;
    font-size: calc(2.5vw + 2.5vh);
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid gray;
}
.schedule-day {
    display: flex;
    justify-content: center;
    width: 50%;
}
.schedule-hours {
    display: flex;
    justify-content: center;
    width: 50%;
}
  
.schedule-day-container {
    margin: 0 0;
    font-size: calc(2vw + 2vh);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    height: 100%;
}
.schedule-day-container:hover {
    background-color: rgba(104, 187, 89, 0.7);
    color: black;
}
.schedule-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
@media screen and (max-width: 1100px) {
    .schedule-text-title {
        margin-top: 4vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .schedule-text-body {
        margin-top: 3vh;
        font-size: 1.5rem;
        font-size: 2.5vh;
    }
}