.history-container {
    position: absolute;
    top: 80px;
    bottom: 200px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.history-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.history-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.history-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
    text-align: center;
}
.history-text-body {
    color: white;
    font-size: 2.5rem;
    font-size: 3.5vh;
    margin-top: 6vh;
    position: absolute;
    top: 7vh;
    bottom: 10vh;
    overflow: auto;
    background-color: rgba(0,0,0, 0.3);
    height: 78%;
    width: 90%;
    transition: transform 0.2s;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.history-text-body:hover {
    transform: scale(1.05);
}
.history-contents {
    display: flex;
    align-content: center;
    justify-content: center;
}
.history-paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    border: 1px solid gray;
    width: 100%;
    box-sizing: border-box;
    font-size: calc(1.4vw + 1.4vh);
}
.history-paragraph-title {
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid gray;
}
.history-paragraph-text {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-grow: 1;
    margin-top: 1%;
    font-size: calc(1.4vw + 1.4vh);
    overflow-y: auto;
}
.board {
    height: 50%;
    border: 1px solid gray;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.board-title {
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid gray;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.board-members {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    flex-grow: 1;
}
.council-member-title {
    background-color: rgba(0,0,0, 0.3);
    border: 1px solid gray;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.council-member-name {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.council-member-name:hover {
    background-color: rgba(104, 187, 89, 0.7);
    color: black;
}
.history-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
@media screen and (max-width: 1100px) {
    .history-text-title {
        margin-top: 4vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .history-text-body {
        margin-top: 3vh;
        font-size: 1.5rem;
        font-size: 2.5vh;
        font-size: 1rem;
    }
    .history-paragraph-title {
        background-color: rgba(0,0,0, 0.3);
        border: 1px solid gray;
        font-size: calc(1.5vw + 1.5vh);
    }
    .history-paragraph {
        height: 50%;
        border: 1px solid gray;
        width: 100%;
        box-sizing: border-box;
        font-size: calc(1.2vw + 1.2vh);
    }
    .history-paragraph-text {
        margin-top: 5%;
        font-size: calc(1.2vw + 1.2vh);
    }
}