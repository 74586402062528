.home-container {
    position: absolute;
    top: 80px;
    bottom: 160px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0, 0.4);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-text {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
}
.home-text-divider-top {
    color: white;
    background-color: white;
    position: absolute;
    top: 2vh;
    width: 90%;
}
.home-text-logo {
    align-self: center;
    max-width: 60%;
    max-height: 35vh;
    margin-top: 8vh;
    pointer-events: none;
    user-select: none;
}
.home-text-title {
    margin-top: 4vh;
    color: white;
    font-size: 4rem;
    font-size: 7vh;
}
.home-text-body {
    color: white;
    font-size: 2rem;
    font-size: 3.5vh;
    margin-top: 6vh;
}
.home-text-divider-bottom {
    color: white;
    background-color: white;
    position: absolute;
    bottom: 2vh;
    width: 90%;
}
@media screen and (max-width: 1100px) {
    .home-text-logo {
        margin-top: 10vh;
        align-self: center;
        max-width: 25vh;
        max-height: 25vh;
    }
    .home-text-title {
        margin-top: 1vh;
        font-size: 2rem;
        font-size: 4vh;
    }
    .home-text-body {
        font-size: 1.5rem;
        font-size: 3vh;
        margin-top: 10%;
    }
}