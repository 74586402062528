  * {
    margin: 0;
  }
  .navbar {
    background-color: rgba(129,199,132, 0.6);
    background-color: rgba(104, 187, 89, 0.7);
    background-color: rgba(134, 200, 188, 0.9);
    background-color: rgba(104, 187, 89, 0.7);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .nav-divider {
    display: none;
  }
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  .navbar-name{
    font-size: 20px;
    color: #000;
    margin-left: 5px;
    display:flex;
    max-width: 100px;
    max-height: 75px;
  }
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    max-width: 300px;
    max-height: 75px;
  }
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(11, auto);
    grid-gap: 0px;
    list-style: none;
    text-align: center;
    width: 80vw;
    justify-content: center;
    margin-right: 2rem;
  }
  .nav-item {
    margin: 0 5px;
  }
  .nav-links {
    display: flex;
    align-items: top;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  .nav-links:hover {
    color: #fff;
    border-bottom: 4px solid #000;    
    transition: all 0.2s ease-out;
  }
  .nav-links.active {
    color: #000;
    border-bottom: 4px solid #000;
  }
  .nav-links:hover + .nav-links.active {
    display: block;
  }
  .nav-item-social {
    height: 30px;
  }
  .nav-links-social {
    color: #fff;
    display: flex;
    align-items: top;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 133%;
  }
  .nav-links-social:hover {
    color: #fff;
    border-bottom: 4px solid #000;
    transition: all 0.2s ease-out;
  }
  .fa-facebook {
    padding-top: 2px;
    width: 20%;
  }
  .fa-youtube {
    padding-top: 2px;
    width: 20%;
  }
  .fa-instagram {
    padding-top: 2px;
    width: 20%;
  }
  .fa-bars {
    color: #000;
  }
  .fa-times {
    color: #000;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  .nav-social-name{
    display: none;
  }
  @media screen and (max-width: 1500px) {
    .nav-menu{
      margin-right: 0;
      margin-left: 0;
    }
    .navbar-logo {
        position: absolute;
        top: 5;
        left: 0;
    }
    .navbar-name {
      display: none;
    }
  }
  @media screen and (max-width: 1250px) {
    .nav-menu {
      display: none;
    }
    .NavbarItems {
      position: relative;
    }
    .navbar-logo {
        position: absolute;
        top: 5;
        left: 0;
    }
    .navbar-name{
          font-size: clamp(16px, 18px, 20px);
          max-width: 50px;
          margin-left: 80px;
          display: flex;
    }
    .nav-menu.active {
      display: flex;
      flex-direction: column;
      background-color: rgba(104, 187, 89, 1);
      position: absolute;
      top: 80px;
      padding-top: 0;
      opacity: 0.9;
      transition: all 0.5s ease;
      z-index: 1;
      width: 40%;
      padding: 0;
      margin-left: 60%;
    }
    .nav-item {
      margin: 0;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
    }
    
    .nav-links {
      color: #000;
      width: 100%;
      margin-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
    }
  
    .nav-links:hover {
      border-radius: 0;
      margin-right: 0;
      border-bottom: none;
    }
    .nav-links.active {
      color: #fff;
      background-color: rgba(0,0,0, 0.7);
      transition: all 0.2s ease-out;
      margin-right: 0;
      border-bottom: none;
    }
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 70%);
      font-size: 1.8rem;
      cursor: pointer;
      width: 5%;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 100%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      transition: 250ms;
      margin-left: 0;
    }

    .nav-item-social {
        width: 100%;
        height: auto;
        display: none;
      }
    .nav-links-social {
        color: #fff;
        display: flex;
        align-items: top;
        text-decoration: none;
        padding: 0.5rem 0.5rem;
        height: 100%;
      }
    .nav-links-social:hover {
        background-color: #fff;
        color: #242424;
    }
    .nav-social-name{
        display: flex;
        align-self: right;
      }
    .nav-divider {
      display: block;
      border-top:1px solid grey;
      margin-left:15px;
      margin-right:15px;
      display: none;
    }
    .language-switcher {
      position: absolute;
      right: 15%;
    }
  }